import React from "react";
import { Col, Row } from "react-bootstrap";
import { isMobile } from "react-device-detect";

export default ({ children, card, noSpacing, className, bg }) => {
  const sectionValue = (
    <Row className={`${className} `}>
      <Col className="m-xs-0 p-xs-0">
        <div
          className={`w-100 ${!isMobile ? "rounded" : ""} ${
            noSpacing ? "" : "px-4 px-md-0 py-4"
          } ${bg === "white" ? `bg-white` : ""}`}
        >
          {children}
        </div>
      </Col>
    </Row>
  );
  return sectionValue;
};
