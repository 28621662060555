import { faKey, faSignOut } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import { Link } from "react-router-dom";
import logo from "../../Assets/img/Logo.svg";
import wyvern from "../../Assets/img/wyvern.svg";
import { b2cPolicies } from "../../AuthConfig";
import CustomAvatar from "../CustomAvatar";
import "./Header.scss";
import IdentityUtils from "../../Infrastructure/IdentityUtils";
import CustomTitle from "../CustomTitle";
import Section from "../Section";
import { PageContext } from "../../Infrastructure/PageContext";
import { AuthContext } from "../../Infrastructure/AuthContext";
import AuthService from "../../Infrastructure/Services/AuthService";
import { Waypoint } from "react-waypoint";
import parsePhoneNumberFromString from "libphonenumber-js";
export default ({ title, handleMenuButtonClick, isMenuVisible, safeAreaBounds }) => {
  const [userDropdownIsOpen, setUserDropdownIsOpen] = useState(false);
  const pageContext = useContext(PageContext);
  const authContext = useContext(AuthContext);
  const [showStaticHeader, setShowStaticHeader] = useState(false);
  const AvatarTo = React.forwardRef(({ children, onClick }, ref) => (
    <a
      href=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      className="dropdown-toggle"
    >
      {children}
    </a>
  ));

  const changePassword = (e) => {
    e.preventDefault();
    if (
      authContext.user &&
      authContext.user.claims.tid &&
      authContext.user.claims.tid === "255e6cd5-dcfb-4c4c-9698-19bade1d2f33"
    ) {
      window.location.href =
        "https://account.activedirectory.windowsazure.com/ChangePassword.aspx";
    } else {
      // instance.acquireTokenRedirect(b2cPolicies.authorities.editProfile);
    }
  };

  const logout = async () => {
    sessionStorage.clear();
    await AuthService.logout();
    authContext.logout();
  };

  return (
    <>
      <header className="bg-white d-none d-md-block" style={styles.header}>
        <div className="align-items-center" style={styles.container}>
          <Container>
            <Row className="d-flex align-items-center">
              <Col>
                <Link
                  to="/"
                  className="d-flex align-items-center me-md-auto link-dark text-decoration-none py-3"
                >
                  <img src={logo} style={styles.logo} alt="My Westminster" />
                </Link>
              </Col>
              <Col className="text-right">
                {authContext.user && (
                  <div className="float-end">
                    <Dropdown align="end">
                      <Dropdown.Toggle variant="" id="dropdown-basic" className="border-0">
                        <CustomAvatar
                          id={authContext.user.claims.synergeticId}
                          name={authContext.user.claims.name}
                          size={32}
                        />
                      </Dropdown.Toggle>

                      <Dropdown.Menu className="border-0 shadow-lg">
                        <Dropdown.Item onClick={changePassword}>
                          <FontAwesomeIcon icon={faKey} className="me-2" />
                          <small>Change Password</small>
                        </Dropdown.Item>
                        <Dropdown.Divider />
                        <Dropdown.Item onClick={logout}>
                          <FontAwesomeIcon icon={faSignOut} className="me-2" />
                          <small>Sign Out</small>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                )}
              </Col>
            </Row>
          </Container>
          <div className="w-100 border-bottom"></div>
          <Container>
            <Row>
              <Col>
                <ul className="list-inline py-0 m-0 fw-semibold">
                  <li className="px-3 ps-0 py-3 list-inline-item">
                    <Link to="/" className="text-decoration-none text-muted">
                      Home
                    </Link>
                  </li>
                  <li className="px-3 py-3 list-inline-item">
                    <Link
                      to="/my-timetable"
                      className="text-decoration-none text-muted"
                    >
                      My Timetable
                    </Link>
                  </li>
                  <li className="px-3 py-3 list-inline-item">
                    <Link
                      to="/contacts"
                      className="text-decoration-none text-muted"
                    >
                      My Contacts
                    </Link>
                  </li>
                  <li className="px-3 py-3 list-inline-item">
                    <Link
                      to="/ask-westminster"
                      className="text-decoration-none text-muted"
                    >
                      My Answers
                    </Link>
                  </li>
                </ul>
              </Col>
            </Row>
          </Container>
        </div>
      </header>
      <div
        className="d-flex align-content-md-center flex-column"
        style={{
          maxHeight: 360,
          backgroundColor: "#00502f",

          backgroundImage: `url(${wyvern})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "75%",
          backgroundPosition: "190% 20%",

          overflow: "hidden",
        }}
      >
        <Container className={`d-md-none`}>
          <Row
            className={`px-1 mobile-header ${
              showStaticHeader ? "show-header" : ""
            } w-100`}
            style={{
              ...styles.fixedHeader,
              ...(isMenuVisible ? { marginLeft: 260 } : {}),
              paddingTop: parseInt(safeAreaBounds.top ?? "0")
            }}
          >
            <Col className="m-xs-0 p-xs-0" xs={3}>
              <header
                className="pt-3 px-0 px-md-5 d-block d-md-none"
                style={styles.header}
              >
                <div
                  className="container-fluid align-items-center"
                  style={styles.container}
                >
                  <div className="dropdown float-start d-md-none">
                    <button
                      style={{
                        zIndex: 2,
                        display: "block",
                        position: "relative",
                      }}
                      className={`hamburger hamburger--slider ${
                        isMenuVisible ? "is-active" : ""
                      } px-0`}
                      type="button"
                      onClick={handleMenuButtonClick}
                    >
                      <span className="hamburger-box">
                        <span className="hamburger-inner"></span>
                      </span>
                    </button>
                  </div>
                </div>
              </header>
            </Col>
            <Col>
              <h6 className="text-white text-center pt-3">
                {showStaticHeader ? pageContext.pageContext.title : ""}
              </h6>
            </Col>
            <Col xs={3}></Col>
          </Row>
        </Container>

        <Container className="pt-5 pt-md-0">
          <Section>
            <Waypoint
              onEnter={(c) => setShowStaticHeader(false)}
              onLeave={(c) => setShowStaticHeader(true)}
            />
            <CustomTitle
              pageTitle
              light
              title={pageContext.pageContext.title}
              pretitle={pageContext.pageContext.pretitle}
              rightComponent={pageContext.pageContext.rightComponent}
            />
          </Section>
        </Container>
      </div>
    </>
  );
};

const styles = {
  container: {
    gridTemplateColumns: "1fr 2fr",
  },
  header: {},
  logo: {
    maxHeight: 30,
  },
  dropdown: {
    marginTop: 32,
    border: 0,
    width: 400,
  },
  fixedHeader: {
    position: "fixed",
    top: 0,
    zIndex: 2,
    transition: "all 0.5s ease-in-out",
  },
};
