import Constants from "../constants";

import authConfig from "../../AuthConfig";
import AuthService from "./AuthService";
import PubSub from "pubsub-js";
let hasRetried = false;
const apiService = {
  get: async (path) => {
    return apiService._call("GET", path, null);
  },
  post: async (path, data) => {
    return apiService._call("POST", path, data);
  },

  _call: async (method, path, data) => {
    const url = Constants.api.url + path;

    const result = await AuthService.login();
    PubSub.publishSync("auth", result);
    const accessToken = result.accessToken;

    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);
    headers.append("Accept", "application/json");
    headers.append("Content-Type", "application/json");
    const options = {
      method: method,
      headers: headers,
    };

    if (method === "POST" && data) {
      options.body = JSON.stringify(data);
    }

    return fetch(url, options).then((response) => {
      if (!response.ok && response.status === 401 && !hasRetried) {
        hasRetried = true;
      }
      if (response.ok) {
        hasRetried = false;
        if (response.status === 204) {
          return {};
        }
        return response.json();
      }
    });
  },
};

export default apiService;
