import React, { useContext, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import RightBar from "../Components/RightBar";
import { PageContext } from "../Infrastructure/PageContext";
import { Capacitor } from "@capacitor/core";

export default ({ children, title, preTitle, rightComponent }) => {
  const pageContext = useContext(PageContext);

  useEffect(() => {
    pageContext.setPageContext({
      title,
      pretitle: preTitle,
      rightComponent,
    });
  }, [title, preTitle]);

  return (
    <>
      <Container>
        <Row>
          <Col>{children}</Col>
          <RightBar />
        </Row>
      </Container>
    </>
  );
};
