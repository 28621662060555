import React from "react";
import { Col, Row } from "react-bootstrap";
export default ({
  pretitle,
  title,
  rightComponent,
  icon,
  pageTitle,
  light,
  variant,
}) => {
  const renderTitle = () => {
    const content = (
      <>
        {icon}
        <span>{title}</span>
      </>
    );

    if (!pageTitle)
      return (
        <h5 className={`mb-0 fw-bold ${light ? " text-white" : ""}`}>
          {content}
        </h5>
      );

    return (
      <>
        {variant == "h2" ? (
          <h6 className={`mb-0 fw-bold ${light ? " text-white" : ""}`}>
            {content}
          </h6>
        ) : (
          <h1 className={`mb-0 fw-bold ${light ? " text-white" : ""}`}>
            {content}
          </h1>
        )}
      </>
    );
  };

  return (
    <div className={`p-0 ${pageTitle ? "my-5 " : " mb-3"}`}>
      <Row>
        <Col className="align-self-center align-items-start text-start">
          {pretitle && (
            <h4
              className={`mb-1 fw-normal ${
                light ? " text-white opacity-50" : " text-muted"
              }`}
            >
              {pretitle}
            </h4>
          )}
          <div className="d-flex justify-content-between align-items-center">
            {renderTitle()}
            {rightComponent && <>{rightComponent}</>}
          </div>
        </Col>
      </Row>
    </div>
  );
};
