import {
  faChevronLeft,
  faChevronRight,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import parsePhoneNumber from "libphonenumber-js";
import React, { useEffect, useState } from "react";
import Avatar from "react-avatar";
import {
  Alert,
  Button,
  Col,
  ListGroup,
  ListGroupItem,
  Row,
} from "react-bootstrap";
import { loginRequest } from "../../AuthConfig";
import CustomButton from "../../Components/CustomButton";
import Loading from "../../Components/Loading";
import Section from "../../Components/Section";
import apiService from "../../Infrastructure/Services/ApiService";
import CacheService, {
  CacheKeys,
} from "../../Infrastructure/Services/CacheService";
import SidebarLayout from "../../Layouts/SidebarLayout";
import CustomAvatar from "../../Components/CustomAvatar";
import ContactCard from "../../Components/ContactCard";

export default () => {
  const [categories, setCategories] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSubCategory, setSelectedSubCategory] = useState(null);
  
  const getContacts = async () => {
    const data = await CacheService.get(
      CacheKeys.incidentContacts,
      60,
      async () =>
        await apiService
          .get("home/critical-contacts")
          .then((r) => {
            return r.sort((c) => c.Order);
          })
         
    );

    setCategories(data);
  };
  useEffect(() => {
    getContacts();
  }, []);

  const renderContact = (contact) => {
    var p = parsePhoneNumber(contact.telephoneNumber, "AU");
    const formattedNumber = Intl.DateTimeFormat()
      .resolvedOptions()
      .timeZone.startsWith("Australia/")
      ? p.formatNational()
      : p.formatInternational();
    return (
      <ListGroupItem
        className={`p-3 d-flex justify-content-start align-items-start cursor-pointer`}
        key={`person_${contact.id}`}
      >
        <ContactCard {...{
          name: contact.name,
          jobTitle: contact.position,
          id: contact.synergeticId,
          telephone: formattedNumber
        }}></ContactCard>
       
      </ListGroupItem>
    );
  };

  const selectCategory = (category) => {
    setSelectedCategory(category);

    if (category.areas.length == 1) {
      setSelectedSubCategory(category.areas[0]);
    } else {
      setSelectedSubCategory(null);
    }
  };

  const goBack = () => {
    if (selectedSubCategory != null) {
      setSelectedSubCategory(null);

      if (selectedCategory.areas.length == 1) setSelectedCategory(null);
    } else {
      setSelectedCategory(null);
    }
  };
  return (
    <>
      <Row>
        <Col>
          <Alert variant="danger" className="text-center mb-3">
            <b>In any event, if necessary call 000 first</b>
          </Alert>
        </Col>
      </Row>

      {categories ? (
        <>
          <Row>
            <Col
              xs={12}
              md={4}
              className={`${selectedCategory ? "d-none d-md-block" : ""}`}
            >
              <ListGroup>
                {categories.map((category) => (
                  <ListGroupItem
                    key={`category_${category.id}`}
                    className={`p-3 d-flex justify-content-between align-items-center cursor-pointer ${
                      selectedCategory && selectedCategory.id == category.id
                        ? "active"
                        : ""
                    }`}
                    onClick={() => selectCategory(category)}
                  >
                    <div>{category.name}</div>
                    <FontAwesomeIcon
                      icon={faChevronRight}
                      className="float-end ms-2"
                    />
                  </ListGroupItem>
                ))}
              </ListGroup>
            </Col>
            {selectedCategory && (
              <Col
                xs={12}
                md={4}
                className={`${selectedSubCategory ? "d-none d-md-block" : ""}`}
              >
                <ListGroup>
                  {selectedCategory.areas.map((category) => (
                    <ListGroupItem
                      key={`selectedCategory_${category.id}`}
                      className={`p-3 d-flex justify-content-between align-items-center cursor-pointer ${
                        selectedSubCategory &&
                        selectedSubCategory.id == category.id
                          ? "active"
                          : ""
                      }`}
                      onClick={() => setSelectedSubCategory(category)}
                    >
                      <div>{category.name || "All Events"}</div>
                      <FontAwesomeIcon
                        icon={faChevronRight}
                        className="float-end ms-2"
                      />
                    </ListGroupItem>
                  ))}
                </ListGroup>
              </Col>
            )}
            {selectedSubCategory && (
              <Col xs={12} md={4}>
                <ListGroup>
                  {selectedSubCategory.people.map((contact) =>
                    renderContact(contact)
                  )}
                </ListGroup>
              </Col>
            )}
          </Row>
          <Row>
            <Col
              xs={12}
              className={`mt-3 ${
                selectedCategory || selectedSubCategory ? "d-md-none" : "d-none"
              }`}
            >
              <CustomButton
                variant="outline-primary"
                outline
                className="w-100 d-flex justify-content-center align-items-center"
                onClick={goBack}
              >
                <FontAwesomeIcon icon={faChevronLeft} className="me-2" />
                Back
              </CustomButton>
            </Col>
          </Row>
        </>
      ) : (
        <div className="w-100 text-center d-flex justify-content-center">
          <Loading />
        </div>
      )}
    </>
  );
};
