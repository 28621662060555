import CustomAvatar from "./CustomAvatar";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";
import parsePhoneNumber from "libphonenumber-js";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import RoleWrapper from "./RoleWrapper";
import teamsIcon from "../Assets/img/teamsIcon.svg";
export default ({
  name,
  jobTitle,
  emailAddress,
  telephone,
  profile,
  image,
  className,
  id,
}) => {
  
  const renderTelephone = (telephoneNumber) => {
    if (!telephoneNumber && !name) return <Skeleton count={1} width={"40%"} />;
    if (!telephoneNumber) return <></>;
    var p = parsePhoneNumber(telephoneNumber, "AU");
    const formattedNumber = Intl.DateTimeFormat()
      .resolvedOptions()
      .timeZone.startsWith("Australia/")
      ? p.formatNational()
      : p.formatInternational();

    return (
      <div className="d-flex align-content-center align-items-center mt-2">
        <a href={p.getURI()} className="text-decoration-none">
          {/* <FontAwesomeIcon icon={faPhone} className="me-2" style={styles.contactIcon} /> */}
          <span className="d-inline-block"> {formattedNumber}</span>
        </a>
      </div>
    );
  };

  const renderEmail = (emailAddress) => {
    if (!emailAddress && !name) return <Skeleton count={1} width={"50%"} />;
    if (!emailAddress) return <></>;
    return (
      <div className="d-flex align-content-center align-items-center mt-2">
        {!emailAddress ? (
          <Skeleton count={1} />
        ) : (
          <a href={`mailTo:${emailAddress}`} className="text-decoration-none">
              {/* <FontAwesomeIcon icon={faEnvelope} className="me-2" style={styles.contactIcon} /> */}
            <span className="d-inline-block"> {emailAddress}</span>
          </a>
        )}
      </div>
    );
  };

  const renderTeams = (emailAddress) => {
    if (!emailAddress && !name) return <Skeleton count={1} width={"15%"} />;
    if (!emailAddress) return <></>;

    return (
      <div className="d-flex align-content-center align-items-center mt-2">
        <a
          href={`https://teams.microsoft.com/l/chat/0/0?users=${emailAddress}`}
          target="_blank"
          className="text-decoration-none"
        >
          <span style={styles.contactIcon} className="me-2">
            <img
              src={teamsIcon}
              alt="Chat on Teams"
              style={styles.contactIcon}
            />
          </span>
          <span className="d-inline-block">Open in Teams</span>
        </a>
      </div>
    );
  };
  return (
    <div className={className}>
      <div className="me-3 mb-2 float-start">
        {!(profile || image) && !name && (
          <Skeleton width={48} height={48} count={1} circle />
        )}
        {name && (
          <CustomAvatar size={48} id={id} imageContent={profile || image} name={name} />
        )}
      </div>

      <div style={{ overflow: "hidden" }}>
        {!name ? (
          <Skeleton count={2} width={"30%"} />
        ) : (
          <>
            <b>{name}</b>
            <br />
            {jobTitle && <>{jobTitle}</>}
          </>
        )}

        <br />
        <div className="mt-2">
          {renderEmail(emailAddress)}
          {renderTelephone(telephone)}
          <RoleWrapper roles={["Student", "Staff"]}>
            {renderTeams(emailAddress)}
          </RoleWrapper>
        </div>
      </div>
    </div>
  );
};

const styles = {
  contactIcon: { display: "inline-block", width: 16, textAlign: "center" },
  teamsIcon: { textTransform: "uppercase", fontWeight: 600 },
};
