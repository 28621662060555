import React from "react";
import "./Carousel.scss";
export default ({ items, id }) => {
  return (
    <div className="slides" id={id}>
      {items.map((item, index) => {
        return (
          <div id={`slide-${index}`} key={`slide-${index}`}>
            {item}
          </div>
        );
      })}
    </div>
  );
};
