import React, { useEffect, useState } from "react";
import Avatar from "react-avatar";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { loginRequest } from "../AuthConfig";
import apiService from "../Infrastructure/Services/ApiService";

export default ({ id, name, size, imageContent }) => {
  const [image, setImage] = useState(imageContent);

  const getAvatar = async () => {
    const data = await apiService
      .get(`/home/picture/${id}`)
      .then((r) => {
        if (r && r.image) setImage(r.image);
      })
      .catch((e) => {
        setImage(null);
      });
  };

  useEffect(() => {
    if (id) getAvatar();
  }, [id]);

  return image ? (
    <div
      style={{
        width: size,
        height: size,
        float: "left",
        borderRadius: 32,
        backgroundImage: `url("data:image/png;base64,${image}")`,
        backgroundPosition: "center center",
        backgroundSize: "cover",
      }}
    />
  ) : (
    <div style={{ width: size, height: size, float: "left", borderRadius: 32 }}>
      <Avatar name={name} size={size} round />
    </div>
  );
};
