import React, { useRef } from "react";
import { isMobileOnly } from "react-device-detect";
import { BottomSheet } from "react-spring-bottom-sheet";
import Modal from "react-bootstrap/Modal";
import "react-spring-bottom-sheet/dist/style.css";
import { Row } from "react-bootstrap";
import { Col } from "react-bootstrap/esm";
export default ({ open, title, size, children, handleClose }) => {
  const sheetRef = useRef();

  return (
    <>
      {isMobileOnly ? (
        <BottomSheet
          open={open}
          ref={sheetRef}
          onDismiss={handleClose}
          snapPoints={({ minHeight, maxHeight }) => {
            return [minHeight, maxHeight * 0.9];
          }}
        >
          <div className="p-4">
            {title && (
              <Row>
                <Col>
                  <h6>
                    <b>{title}</b>
                  </h6>
                </Col>
              </Row>
            )}
            {children}
          </div>
        </BottomSheet>
      ) : (
        <>
          <Modal
            show={open}
            onHide={handleClose}
            size={size}
            className="shadow"
          >
            <Modal.Header
              closeButton
              className="d-flex align-content-center align-items-center"
            >
              <Modal.Title>
                <h6 className="fw-semibold p-0 m-0">{title}</h6>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>{children}</Modal.Body>
          </Modal>
        </>
      )}
    </>
  );
};

const styles = {
  bottomSheet: {
    marginTop: 50,
    maxHeight: "90vh",
  },
};
