import React from "react";
import { Card } from "react-bootstrap";

export default ({ colour, text, children, className, style, onClick }) => {
  const textColor = text ? `text-${text}` : null;
  const props = {};
  if (onClick){
    props.onClick = onClick;
  }
  return (
    <Card bg={colour} className={className} style={{ ...style }} {...props}>
      <Card.Body>
        <div className={textColor}>{children}</div>
      </Card.Body>
    </Card>
  );
};
