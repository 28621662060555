import React from "react";
import "./TabBar.scss";
export default ({ tabs, selectedIndex, onTabIndexChanged }) => {
  return (
    <div className="scrolling-wrapper-flexbox tab-container border-bottom p-0 mb-4">
      {tabs &&
        tabs.map((tab, index) => {
          return (
            <div
              key={`tab-${index}`}
              className={`tab-item px-3 py-2 cursor-pointer d-flex align-items-center ${
                selectedIndex === index ? "tab-item-selected" : ""
              }`}
              onClick={() => onTabIndexChanged(index)}
            >
              {tab}
            </div>
          );
        })}
    </div>
  );
};
