import React, { useState, useEffect } from "react";
import { Col } from "react-bootstrap";
import CustomCard from "../../Components/CustomCard";

export default ({ structure, timetable, day, view, onLessonClick }) => {
  const [dayPeriods, setDayPeriods] = useState([]);
  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  let timer;
  useEffect(() => {
    render();
    timer = setInterval(() => {
      render();
    }, 60000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  const render = () => {
    let periods = [];
    structure.days["0"].map((period) => {
      const startTimeParts = period.timeFrom.split(":");
      const startTime = new Date();
      startTime.setHours(startTimeParts[0]);
      startTime.setMinutes(startTimeParts[1]);
      startTime.setSeconds(0);
      period.startTime = startTime;

      const endTimeParts = period.timeTo.split(":");
      const endTime = new Date();
      endTime.setHours(endTimeParts[0]);
      endTime.setMinutes(endTimeParts[1]);
      endTime.setSeconds(0);
      period.endTime = endTime;
      periods.push(period);
    });
    const currentDay = new Date().getDay().toString();

    if (structure.days[currentDay]) {
      structure.days[currentDay].map((period) => {
        const existingPeriod = period.findIndex(
          (p) =>
            p.id === period.id && p.periodNumberSeq === period.periodNumberSeq
        );
        if (existingPeriod > -1) {
          periods.splice(existingPeriod, 1, period);
        }
      });
    }

    periods = periods.sort((a, b) => {
      if (a.periodNumber > b.periodNumber) {
        return 1;
      } else if (a.periodNumber < b.periodNumber) {
        return -1;
      }

      // Else go to the 2nd item
      if (a.periodNumberSeq < b.periodNumberSeq) {
        return -1;
      } else if (a.periodNumberSeq > b.periodNumberSeq) {
        return 1;
      } else {
        // nothing to split them
        return 0;
      }
    });

    setDayPeriods(periods);
  };

  const formatDate = (date) => {
    let minutes = date.getMinutes().toString();
    if (minutes.length == 1) {
      minutes = minutes + "0";
    }
    const hours = date.getHours();
    const ampm = hours >= 12 ? "pm" : "am";
    return `${hours}:${minutes}${ampm}`;
  };

  const getNowBarBackground = (period) => {
    if (dayPeriods.length === 0) return "";

    let today = new Date();
    if (
      day == today.getDay() &&
      period.startTime <= today &&
      period.endTime >= today
    ) {
      const startTime = new Date();
      const startTimeParts = period.timeFrom.split(":");
      startTime.setHours(startTimeParts[0]);
      startTime.setMinutes(startTimeParts[1]);
      startTime.setSeconds(0);

      const endTime = new Date();
      const endTimeParts = period.timeTo.split(":");
      endTime.setHours(endTimeParts[0]);
      endTime.setMinutes(endTimeParts[1]);
      endTime.setSeconds(0);
      //calculate total minutes in a day
      var diffMs = endTime - startTime;
      var diffMins = diffMs / 1000 / 60;

      //calculate total minutes from start time to current time
      var nowMs = today - startTime;
      var nowMins = nowMs / 1000 / 60;

      //calculate percentage of day passed
      var percentage = ((nowMins / diffMins) * 100).toFixed(0) + "%";

      return {
        backgroundImage: "linear-gradient(red, red)",
        backgroundSize: "100% 1px" /* add your height of the line here*/,
        backgroundRepeat: "no-repeat",
        backgroundPosition: `${percentage} ${percentage}` /*place it in the middle */,
      };
    }
    return {};
  };

  const renderPeriod = (period) => {
    const timetabledLesson =
      period.periodNumberSeq === 1
        ? timetable.lessons.find(
            (x) => x.day === day && x.period === period.periodNumber
          )
        : null;

    const startTimeParts = period.timeFrom.split(":");
    const endTimeParts = period.timeTo.split(":");

    const startDateTime = new Date();
    startDateTime.setHours(startTimeParts[0]);
    startDateTime.setMinutes(startTimeParts[1]);
    startDateTime.setSeconds(0);

    const endDateTime = new Date();
    endDateTime.setHours(endTimeParts[0]);
    endDateTime.setMinutes(endTimeParts[1]);
    endDateTime.setSeconds(0);

    var diffMs = endDateTime - startDateTime;
    var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000);
    let className = "p-1 mx-0 my-1 w-100 rounded p-3 overflow-hidden small";

    className += timetabledLesson
      ? " bg-primary text-white cursor-pointer"
      : " bg-grey-300";

    const props = {};
    if (timetabledLesson) {
      props.onClick = () => onLessonClick(timetabledLesson);
    }
    return (
      <div
        key={`lesson${day}-${period.periodNumber}-${period.periodNumberSeq}`}
        {...props}
        className={className}
        style={{
          minHeight: diffMins * 4,
          border: 0,
          ...getNowBarBackground(period),
        }}
      >
        <span className="float-start">
          <b>{period.description}</b>
        </span>
        <span className="float-end">
          <b>
            {formatDate(startDateTime)} - {formatDate(endDateTime)}
          </b>
        </span>
        <br />

        {timetabledLesson && (
          <span className="mt-2 inline-block">
            {timetabledLesson.classDescription}
          </span>
        )}
        <br />
        {timetabledLesson && (
          <>
            <span className="float-start mt-2">{timetabledLesson.staff}</span>
            <span className="float-end mt-2">{timetabledLesson.room}</span>
          </>
        )}
      </div>
    );
  };

  return (
    <>
      {dayPeriods && (
        <Col>
          <div className="p-1 mx-0 my-1 w-100 rounded p-3 overflow-hidden small fw-semibold text-center">
            {days[day]}
          </div>
          {dayPeriods.map((period) => renderPeriod(period))}
        </Col>
      )}
    </>
  );
};
