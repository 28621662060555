import { useContext, useEffect, useState } from "react";
import { Route, Switch, Redirect } from "react-router-dom";

// Sample app imports
import Home from "./Areas/Home/HomePage";

import Layout from "./Layouts/DefaultLayout";
import "./Styles/App.scss";
import TimetablePage from "./Areas/Timetable/TimetablePage";
import ContactsPage from "./Areas/Contacts/ContactsPage";
import AskWestminsterPage from "./Areas/AskWestminster/AskWestminsterPage";

import AskQuestionPage from "./Areas/AskWestminster/AskQuestionPage";
import { PageContext } from "./Infrastructure/PageContext";
import { AuthContext } from "./Infrastructure/AuthContext";
import PubSub from "pubsub-js";
import Login from "./Areas/Auth/Login";
import Splash from "./Areas/Auth/Splash";
import { SplashScreen } from "@capacitor/splash-screen";
import AuthService from "./Infrastructure/Services/AuthService";
import Loading from "./Components/Loading";

function App() {
  const [user, setUser] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const login = (response) => {
    const token = response.idToken;
    if (!token) {
      localStorage.clear();
      window.location.href = window.location.href;
      return;
    }
    try {
      var base64Url = token.split(".")[1];
      var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      var jsonPayload = decodeURIComponent(
        window
          .atob(base64)
          .split("")
          .map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
      );
      const newUser = { claims: JSON.parse(jsonPayload) };
      setUser(newUser);
    } catch (_) {
      localStorage.clear();
      window.location.href = window.location.href;
      return;
    }
  };

  const logout = () => {
    setUser(null);
  };

  var authSubscriber = function (msg, user) {
    if (msg === "auth") login(user);
  };

  const hideSplashScreen = async () => {
    await SplashScreen.hide();
  };
  useEffect(() => {
    var token = PubSub.subscribe("auth", authSubscriber);
    //try and get MSAL user
    const account = AuthService.isUserSignedInWeb();
    if (account) {
      login(account).then(() => {
        setLoaded(true);
        hideSplashScreen().then(() => { });
      });
    } else {
      setLoaded(true);
      hideSplashScreen().then(() => { });
    }
  }, []);

  if (!loaded) return <Loading />;

  return (
    <AuthContext.Provider value={{ user, login, logout }}>
      <Pages />
    </AuthContext.Provider>
  );
}

function Pages() {
  const authContext = useContext(AuthContext);
  const [pageContext, setPageContext] = useState({});
  const value = { pageContext, setPageContext };
  return (
    <>
      {authContext.user && (
        <PageContext.Provider value={value}>
          <Layout>
            <Switch>
              <Route path="/my-timetable">
                <TimetablePage />
              </Route>
              <Route path="/contacts">
                <ContactsPage />
              </Route>
              <Route path="/ask-westminster/ask-a-question">
                <AskQuestionPage />
              </Route>
              <Route path="/ask-westminster/:categoryId?/:categorySlug?/:questionId?/:questionSlug?">
                <AskWestminsterPage />
              </Route>
              <Route path="/">
                <Home />
              </Route>
              <Route path="/auth">
                <Redirect
                  to={{
                    pathname: "/",
                  }}
                />
              </Route>
            </Switch>
          </Layout>
        </PageContext.Provider>
      )}
      {!authContext.user && (
        <Switch>
          <Route path="/Login">
            <Login />
          </Route>
          <Route path="/signin-oidc">
            <Login />
          </Route>
          <Route path="/">
            <Splash />
          </Route>
        </Switch>
      )}
    </>
  );
}

export default App;
