import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { loginRequest } from "../../AuthConfig";
import Loading from "../../Components/Loading";
import apiService from "../../Infrastructure/Services/ApiService";
import CacheService, {
  CacheKeys,
} from "../../Infrastructure/Services/CacheService";
import Category from "./Category";
import NotFound from "../../Components/NotFound";
import './AskQuestions.scss';
export default ({ categorySlug, categoryId }) => {
  const [category, setCategory] = useState();
  const [loaded, setLoaded] = useState(false);

  const getQuestions = async () => {
    const data = await apiService
      .get(`home/ask-westminster/${categoryId}`)
      .then((r) => r);

    setLoaded(true);
    setCategory(data);
  };
  useEffect(() => {
    getQuestions();
  }, []);

  return (
    <>
      {loaded ? (
        <>
          {category ? (
            <Category category={category} noCategoryLink />
          ) : (
            <NotFound item={"Category"} />
          )}
        </>
      ) : (
        <div className="d-flex justify-content-center align-items-center mt-5">
          <Loading />
        </div>
      )}
    </>
  );
};

const styles = {
  list: {
    listStyleType: "none",
    paddingLeft: 0,
  },
};
