import React, { useEffect, useState } from "react";
import Header from "../Components/Header/Header";
import Menu from "../Components/Menu/Menu";
import "./DefaultLayout.scss";
import { Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
} from "@fortawesome/free-solid-svg-icons";
import apiService from "../Infrastructure/Services/ApiService";

import CacheService, {
  CacheKeys,
} from "../Infrastructure/Services/CacheService";
import { Capacitor } from "@capacitor/core";

export default ({ children }) => {
  const rootElement = document.getElementById("root");
  const [menuVisible, setMenuVisible] = useState(false);
  const [alertBar, setAlertBar] = useState();
  const [safeAreaBounds, setSafeAreaBounds] = useState();

  const toggleMenu = () => {
    setMenuVisible((prevState) => !prevState);
  };

  useEffect(() => {
    getData();
    if (!window.plugins || !window.plugins.safearea) {
      setSafeAreaBounds({ top: 0, bottom: 0, left: 0, right: 0 });
    } else {
      window.plugins.safearea.get(
        (safeArea) => setSafeAreaBounds(safeArea),
        () => setSafeAreaBounds({ top: 0, bottom: 0, left: 0, right: 0 })
      );
    }
  }, []);

  useEffect(() => {}, [safeAreaBounds]);

  // useEffect(() => {
  //   if (menuVisible) rootElement.classList.add("overflow-hidden", "vh-100");
  //   else rootElement.classList.remove("overflow-hidden", "vh-100");
  // }, [menuVisible]);

  const getData = async () => {
    const data = await CacheService.get(
      CacheKeys.alert,
      20,
      async () => await apiService.get("/home/alerts").then((r) => r)
    );
    if (data) setAlertBar(data);
  };

  if (!safeAreaBounds) return <></>;

  return (
    <>
      <div
        style={{
          width: "100vh",
          position: "fixed",
          top: 0,
          height: 400,
          zIndex: -1,
        }}
        className="bg-primary"
      ></div>
      <div
        className={`float-start ms-md-0 d-md-none bg-primary`}
        style={{
          position: "absolute",
          width: 280,
          marginLeft: menuVisible ? 0 : -280,
          transition: "all 0.5s ease-in-out",
        }}
      >
        {menuVisible && (
          <div
            style={styles.menuMask}
            onClick={() => setMenuVisible(false)}
          ></div>
        )}
        <Menu
          onItemClick={() => setMenuVisible(false)}
          safeAreaBounds={safeAreaBounds}
        />
      </div>

      <div
        className={`App d-flex flex-column flex-shrink-0 w-md-auto bg-primary ${Capacitor.isNativePlatform() ? 'container-native' : 'container-web'}`}
        style={{
          //marginLeft: menuVisible ? 280 : 0,
          transition: "all 0.5s ease-in-out",
          paddingTop: parseInt(safeAreaBounds.top ?? "0"),
        }}
      >
        {alertBar &&
          new Date(alertBar.startDate) < new Date() &&
          new Date(alertBar.endDate) > new Date() && (
            <div className="bg-primary">
              <div className={`bg-danger text-white alert-bar`}>
                <div className="container">
                  <Row>
                    <Col
                      className="py-3 d-flex align-items-center justify-content-center cursor-pointer"
                      onClick={() => (window.location.href = alertBar.link)}
                    >
                      <div>{alertBar.text}</div>
                      <div>
                        <FontAwesomeIcon icon={faArrowRight} className="ms-3" />
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          )}
        <div id="header-wrapper">
          <Header
            handleMenuButtonClick={toggleMenu}
            isMenuVisible={menuVisible}
            safeAreaBounds={safeAreaBounds}
          />
        </div>
        <div
          style={{
            backgroundColor: "#fff",
            paddingBottom: parseInt(safeAreaBounds.bottom ?? "0"),
          }}
        >
          <main className="pb-5">{children}</main>
        </div>
      </div>
    </>
  );
};

const styles = {
  menuMask: {
    zIndex: 990,
    position: "fixed",
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    background: "rgba(255,255,255,0.3)",
  },
  feedbackButton: {
    position: "fixed",
    right: 20,
    bottom: 20,
    zIndex: 99,
  },
};
