import { faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import logo from "../../Assets/img/Logo.svg";
import Routes from "../../Infrastructure/Routes";
import "./Menu.scss";
import CustomAvatar from "../CustomAvatar";
import AuthConfig, { b2cPolicies } from "../../AuthConfig";
import { AuthContext } from "../../Infrastructure/AuthContext";
import AuthService from "../../Infrastructure/Services/AuthService";

export default ({ onItemClick, safeAreaBounds }) => {
  const authContext = useContext(AuthContext);
  const [user, setUser] = useState(null);

  const location = useLocation();
  const [isAccountMenuOpen, setIsAccountMenuOpen] = useState(false);
  const [activeAccount, setActiveAccount] = useState(null);

  const toggleAccountMenu = () => {
    setIsAccountMenuOpen((prevState) => !prevState);
  };
  const changePassword = (e) => {
    e.preventDefault();

    if (
      authContext.user &&
      authContext.user.claims.tid &&
      authContext.user.claims.tid === "255e6cd5-dcfb-4c4c-9698-19bade1d2f33"
    ) {
      window.location.href =
        "https://account.activedirectory.windowsazure.com/ChangePassword.aspx";
    } else {
      //instance.acquireTokenRedirect(b2cPolicies.authorities.changePassword);
    }
  };
  const logout = async () => {
    sessionStorage.clear();
    await AuthService.logout();
    authContext.logout();
  };

  useEffect(() => {
    if (authContext && authContext.user.claims) {
      setUser({
        id: authContext.user.claims.synergeticId,
        name: authContext.user.claims.name,
        email: authContext.user.claims.email,
        isb2c:
          authContext.user.claims.tenantId !==
          "255e6cd5-dcfb-4c4c-9698-19bade1d2f33",
      });
    } else {
      setUser(null);
    }
  }, []);

  useEffect(() => {}, [safeAreaBounds]);

  const handleItemClick = () => {
    if (isAccountMenuOpen) setIsAccountMenuOpen(false);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
    onItemClick();
  };

  const renderIcon = (icon) => {
    return <></>;
  };

  return (
    <div
      className={`d-flex flex-column flex-shrink-0 ${
        parseInt(safeAreaBounds.top ?? "0") < 10 ? "p-3" : "px-3"
      } bg-light menu`}
      style={{
        width: 280,
        minHeight: "100vh",
        maxHeight: "100vh",
        position: "fixed",
        overflowY: "scroll",
        overflowX: "hidden",
        paddingTop: parseInt(safeAreaBounds.top ?? "0"),
        paddingBottom: parseInt(safeAreaBounds.bottom ?? "0"),
      }}
    >
      <Link
        to="/"
        onClick={handleItemClick}
        className="d-flex align-items-center me-md-auto link-dark text-decoration-none mb-4 pt-2"
      >
        <img src={logo} style={styles.logo} alt="My Westminster" />
      </Link>
      <ul className="nav nav-pills flex-column mb-auto">
        {Routes.filter((route) =>
          route.roles
            ? route.roles.filter(
                (r) =>
                  authContext &&
                  authContext.user &&
                  authContext.user.claims.roles &&
                  authContext.user.claims.roles.includes(r)
              ).length > 0
            : true
        ).map((route) => {
          const isActive = new RegExp(route.activeTest).test(location.pathname);

          return (
            <li className="nav-item" key={route.link}>
              <Link
                to={route.link}
                className={`nav-link px-3 py-2 ${isActive ? "active" : ""}`}
                aria-current="page"
                onClick={handleItemClick}
              >
                {renderIcon(route.icon)}
                {route.title}
              </Link>
            </li>
          );
        })}
      </ul>
      {user && (
        <>
          <hr />
          <div
            className="cursor-pointer d-flex align-items-center justify-content-between"
            onClick={toggleAccountMenu}
          >
            <div className="d-flex align-items-center justify-content-start">
              <CustomAvatar id={user.id} name={user.name} size={32} />
              <div className="ms-2" style={styles.accountItem}>
                <small>
                  <b>{user.name}</b>
                </small>
                <br />
                <small style={{ fontSize: "0.6rem" }}>{user.email}</small>
              </div>
            </div>
            <FontAwesomeIcon
              icon={faChevronUp}
              className="float-end"
              style={{
                transition: "transform 0.5s ease-in-out",
                transform: `rotate(${isAccountMenuOpen ? 180 : 0}deg)`,
              }}
            />
          </div>
          <div
            style={{
              maxHeight: isAccountMenuOpen ? 500 : 0,
              height: "auto",
              overflowY: isAccountMenuOpen ? "visible" : "hidden",
              transition: "max-height 0.5s ease-in-out",
            }}
          >
            <div style={styles.userMenu} className="pt-2">
              <ul style={styles.list}>
                <li className="mb-2" onClick={changePassword}>
                  Change Password
                </li>
                <li className="mb-2" onClick={logout}>
                  Sign Out
                </li>
              </ul>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
const styles = {
  logo: {
    height: 35,
  },
  userMenu: { marginLeft: 44 },
  list: {
    listStyleType: "none",
    paddingLeft: 0,
    fontSize: "0.8rem",
  },
  accountItem: {
    lineHeight: "0.8rem",
  },
};
