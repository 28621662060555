import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { loginRequest } from "../../AuthConfig";
import Loading from "../../Components/Loading";
import apiService from "../../Infrastructure/Services/ApiService";
import CacheService, {
  CacheKeys,
} from "../../Infrastructure/Services/CacheService";
import './AskQuestions.scss';
export default ({ category, noCategoryLink }) => {
  const renderBreadcrumb = () => {
    return (
      <p>
        <Link to="/ask-westminster">My Answers</Link>
        <> / {category.name}</>
      </p>
    );
  };

  return (
    <>
      <Col xs={12} md={6} className="mb-3">
        {noCategoryLink && <div className="mb-4">{renderBreadcrumb()}</div>}
        <h4>
          {noCategoryLink ? (
            <>{category.name}</>
          ) : (
            <Link
              to={`/ask-westminster/${category.id}/${category.slug}`}
              className="text-decoration-none"
            >
              {category.name}
            </Link>
          )}
        </h4>
        <ul style={styles.list}>
          {category.myQuestions.map((question) => {
            return (
              <li key={question.id} className={"py-2"}>
                <p>
                  <Link
                    to={`/ask-westminster/${category.id}/${category.slug}/${question.id}/${question.slug}`}
                    className="text-decoration-none d-inline-block"
                  >
                    {question.name}
                  </Link>
                </p>
              </li>
            );
          })}
        </ul>
      </Col>
    </>
  );
};

const styles = {
  list: {
    listStyleType: "none",
    paddingLeft: 0,
  },
};
