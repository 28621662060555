import IdentityUtils from "../Infrastructure/IdentityUtils";
import { useContext } from "react";
import { AuthContext } from "../Infrastructure/AuthContext";

export default ({ children, roles, displayIf }) => {
  const identity = useContext(AuthContext);

  if (!identity?.user?.claims?.roles) return <></>;

  const display =
    roles
      .map((role) => identity.user.claims.roles.indexOf(role) > -1)
      .filter((r) => r === true).length > 0;
  if (!display) return <></>;

  return <div>{children}</div>;
};
