

export default ({

    clear: () => {
        Object.keys(CacheKeys).forEach(key =>
            sessionStorage.removeItem(`CACHE_${key}`)
        )
    },
    remove: (key) => {
        sessionStorage.removeItem(`CACHE_${key}`);
    },
    get: async (key, ttl, acquire) => {
        let cacheItem = sessionStorage.getItem(`CACHE_${key}`);
        let jsonCacheItem = null;
        if (cacheItem != null) {
            try {
                jsonCacheItem = JSON.parse(cacheItem);
            } catch (exception) { }
        }

        if (jsonCacheItem != null && new Date(jsonCacheItem.expiry) > new Date())
            return jsonCacheItem.data;
        const data = await acquire();

        if (!data)
            return data;
        const expiry = new Date(new Date().getTime() + ttl * 60000);
        const newCacheObject = {
            expiry: expiry,
            data: data
        };
        const jsonNewCacheObject = JSON.stringify(newCacheObject);

        const cacheKey = `CACHE_${key}`;
        sessionStorage.setItem(cacheKey, jsonNewCacheObject);

        return data;
    },

    set: (key, data, ttl) => {


        const expiry = new Date(new Date().getTime() + ttl * 60000);
        const newCacheObject = {
            expiry: expiry,
            data: data
        };
        const jsonNewCacheObject = JSON.stringify(newCacheObject);

        const cacheKey = `CACHE_${key}`;
        sessionStorage.setItem(cacheKey, jsonNewCacheObject);

        return data;
    }
});

export const CacheKeys = {
    homepage: 'homepage',
    calendar: 'calendar',
    contacts: 'contacts',
    incidentContacts: 'incidentContacts',
    askWestminster: 'askWestminster',
    idCard: 'idCard',
    children: 'children',
    alert: 'alert'
};
