import React, { useEffect, useState } from "react";
import { Col, Row, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { loginRequest } from "../../AuthConfig";
import Loading from "../../Components/Loading";
import apiService from "../../Infrastructure/Services/ApiService";
import Section from "../../Components/Section";
import SidebarLayout from "../../Layouts/SidebarLayout";
import CacheService, {
  CacheKeys,
} from "../../Infrastructure/Services/CacheService";
import './AskQuestions.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestion } from "@fortawesome/free-solid-svg-icons";
import { isMobile } from "react-device-detect";
import CustomButton from "../../Components/CustomButton";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export default ({ c }) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [question, setQuestion] = useState("");
  const [step, setStep] = useState(0);
  const askAQuestionButton = (
    <CustomButton
      variant="outline-light"
      size={isMobile ? "sm" : ""}
      onClick={() => history.push("/ask-westminster/ask-a-question")}
    >
      <FontAwesomeIcon icon={faQuestion} className="me-2"></FontAwesomeIcon>
      Ask a Question
    </CustomButton>
  );
  const renderBreadcrumb = () => {
    return (
      <p>
        <Link to="/ask-westminster">My Answers</Link>
        <> / Ask a question</>
      </p>
    );
  };

  const submitQuestion = () => {
    setLoading(true);

    apiService.post("home/ask-westminster/ask", { question }).then((res) => {
      setLoading(false);
      setQuestion("");
      setStep(1);
    });
  };

  return (
    <>
      {" "}
      <SidebarLayout title={"My Answers"} rightComponent={askAQuestionButton}>
        <Section bg="white">
          <Row>
            <Col xs={12} md={12} className="mb-3">
              <div className="mb-4">{renderBreadcrumb()}</div>
              <p>If you have a question that isn't listed here, just submit it using the box below</p>
            </Col>
          </Row>
          {step === 0 && (
            <>
              <Row>
                <Col>
                  <Form.Control
                    as="textarea"
                    required
                    type="text"
                    rows={6}
                    placeholder="Type your question here..."
                    defaultValue={question}
                    onChange={(e) => setQuestion(e.target.value)}
                  />
                </Col>
              </Row>
              <Row>
                <Col className="pt-3 text-center text-md-end">
                  <CustomButton
                    variant="success"
                    className="d-inline-block d-md-inline w-100 w-md-auto"
                    loading={loading}
                    defaultValue={question}
                    onClick={submitQuestion}
                    onChange={(e) => setQuestion(e.target.value)}
                  >
                    Submit
                  </CustomButton>
                </Col>
              </Row>
            </>
          )}

          {step === 1 && (
            <>
              <Row>
                <Col>
                  <p className="fw-bold">
                    Thank you for submitting your question.
                  </p>
                  <p>
                    One of our team will review your question and add it to the
                    My Answers section soon.
                  </p>
                </Col>
              </Row>
            </>
          )}
        </Section>
      </SidebarLayout>
    </>
  );
};

const styles = {
  list: {
    listStyleType: "none",
    paddingLeft: 0,
  },
};
