import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import ContactCard from "../../Components/ContactCard";
import apiService from "../../Infrastructure/Services/ApiService";

export default ({ lesson, timetable }) => {
  const [contact, setContact] = useState(null);

  useEffect(() => {
    getContact();
  }, []);
  const getContact = () => {
    apiService
      .get(`home/calendar/teacher/${timetable.id}/${lesson.staffId}`)
      .then((res) => {
        setContact(res);
      });
  };

  const lessons =
    timetable && lesson
      ? timetable.lessons
          .sort((a, b) => {
            if (a.day > b.day) {
              return 1;
            } else if (a.day < b.day) {
              return -1;
            }

            // Else go to the 2nd item
            if (a.period < b.period) {
              return -1;
            } else if (a.period > b.period) {
              return 1;
            } else {
              // nothing to split them
              return 0;
            }
          })
          .filter((x) => x.class === lesson.class)
      : [];
  if (!lesson || !timetable) return <></>;
  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const formatTime = (time) => {
    const timeParts = time.split(":");

    return `${timeParts[0]}:${timeParts[1]}`;
  };
  const renderTimetabledLesson = (timetabledLesson) => {
    return (
      <tr className="mb-3">
        <td className="mb-3">{days[timetabledLesson.day]}</td>
        <td className="mb-3 text-center">{timetabledLesson.period}</td>
        <td className="mb-3">
          {formatTime(timetabledLesson.timeFrom)} -{" "}
          {formatTime(timetabledLesson.timeUntil)}
        </td>
        <td className="mb-3 text-center">{timetabledLesson.room}</td>
      </tr>
    );
  };

  return (
    <>
      {lesson && (
        <div className="mb-5">
          <Row className="mb-3">
            <Col className="fw-semibold" md={2}>
              Class Code
            </Col>
            <Col>{lesson.class}</Col>
          </Row>
          <Row className="mb-3">
            <Col className="fw-semibold" md={2}>
              Class
            </Col>
            <Col>{lesson.classDescription}</Col>
          </Row>
          <Row className="mb-3">
            <Col className="fw-semibold" md={2}>
              Staff
            </Col>
            <Col>
              <ContactCard {...contact} className={"my-3"} />
            </Col>
          </Row>
          <Row className="mb-3">
            <Col className="fw-semibold" md={2}>
              Timetable
            </Col>
          </Row>
          <Row className="mb-3">
            <Col>
              <table className="table table-striped w-100">
                <thead>
                  <tr>
                    <th className="mb-3">Day</th>
                    <th className="mb-3 text-center">Period</th>
                    <th className="mb-3">Time</th>
                    <th className="mb-3 text-center">Room</th>
                  </tr>
                </thead>
                <tbody>
                  {lessons.map((timetabledLesson) =>
                    renderTimetabledLesson(timetabledLesson)
                  )}
                </tbody>
              </table>
            </Col>
          </Row>
        </div>
      )}
    </>
  );
};
