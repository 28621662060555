import { faCircleNotch, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button } from "react-bootstrap";

export default (props) => {
  const newProps = { ...props };

  const mergedStyle = { ...newProps.style, ...style.button };

  newProps.style = mergedStyle;
  newProps.className = newProps.className
    ? newProps.className + " fw-medium"
    : "fw-medium";

  if (newProps.outline) {
    newProps.className += " btn-outline";
  }
  if (props.fullWidth) {
    newProps.className += " w-100";
  }
  if (props.halfWidth) {
    newProps.className += " w-50";
  }

  if (props.loading) {
    newProps.disabled = true;
  } else {
    newProps.disabled = false;
  }

  return (
    <Button {...newProps}>
      {props.loading && (
        <>
          <FontAwesomeIcon icon={faCircleNotch} spin className="me-2" />
        </>
      )}

      {props.children && <>{props.children}</>}

      {newProps.text ? <>{newProps.text}</> : <></>}
    </Button>
  );
};

const style = {
  button: {},
};
