import { borderColor } from "@mui/system";
import React from "react";
import "./Loading.scss";
import { isMobile } from "react-device-detect";

export default ({ showLogo, colour }) => {
  const loaderColour = `${
    colour || "#00502f"
  } transparent transparent transparent`;

  return (
    <div style={isMobile ? { paddingBottom: 150 } : {}}>
      <div className={`lds-ring ${showLogo ? "loading-logo" : ""}`}>
        <div style={{ borderColor: loaderColour }}></div>
        <div style={{ borderColor: loaderColour }}></div>
        <div style={{ borderColor: loaderColour }}></div>
        <div style={{ borderColor: loaderColour }}></div>
      </div>
    </div>
  );
};
