import { useContext, useEffect } from "react";
import AuthConfig from "../../AuthConfig";
import { AuthContext } from "../../Infrastructure/AuthContext";
import Loading from "../../Components/Loading";
import {
  Redirect,
  useHistory,
} from "react-router-dom/cjs/react-router-dom.min";

import PubSub from "pubsub-js";
import AuthService from "../../Infrastructure/Services/AuthService";

export default () => {
  const authContext = useContext(AuthContext);
  const history = useHistory();
  useEffect(() => {
    if (!authContext || !authContext.user) {
      AuthService.login().then((token) => {
        if (token) {
          PubSub.publishSync("auth", token);
          history.push("/");
        }
      });
    }
  }, []);

  if (!authContext || !authContext.user) return <div className="d-flex justify-content-center align-items-center mt-5 flex-column text-muted"><Loading /></div>;

  return <Redirect to="/"></Redirect>;
};
