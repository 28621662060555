import React from "react";
import { Card, Col } from "react-bootstrap";

export default () => {
  return (
    <>
      {/* <Col xs={12} md={2} className="mt-3 mt-md-0">
        <Card className="p-0s border-0 overflow-hidden">

          <img src={require('../Assets/img/westminster-directory.png')} alt="Westminster Directory" />

        </Card>
      </Col> */}
    </>
  );
};
