import React, { useEffect, useState, useRef, useContext } from "react";
import FullCalendar from "@fullcalendar/react"; // must go before plugins
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import Section from "../../Components/Section";
import SidebarLayout from "../../Layouts/SidebarLayout";
import CacheService, {
  CacheKeys,
} from "../../Infrastructure/Services/CacheService";
import apiService from "../../Infrastructure/Services/ApiService";
import { ButtonGroup, Col, Container, Row } from "react-bootstrap";
import CustomAvatar from "../../Components/CustomAvatar";
import bootstrap5Plugin from "@fullcalendar/bootstrap5";
import "./TimetablePage.scss";
import CustomButton from "../../Components/CustomButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faChevronLeft,
  faCalendarAlt,
} from "@fortawesome/free-solid-svg-icons";
import CustomModal from "../../Components/CustomModal";
import AddTimetablePage from "./AddTimetablePage";
import Loading from "../../Components/Loading";
import TabBar from "../../Components/Tabs/TabBar";
import Lesson from "./Lesson";
import { AuthContext } from "../../Infrastructure/AuthContext";
import { NewspaperOutlined } from "@mui/icons-material";
import TimetableDayView from "./TimetableDayView";
import { faCalendarXmark } from "@fortawesome/free-regular-svg-icons";
export default () => {
  const calendar = useRef();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [timetables, setTimetables] = useState(null);
  const [structure, setStructure] = useState(null);
  const [selectedPerson, setSelectedPerson] = useState(null);
  const [currentDay, setCurrentDay] = useState(new Date().getDay());
  const [view, setView] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedLesson, setSelectedLesson] = useState(null);
  const authContext = useContext(AuthContext);
  const getData = async () => {
    const data = await CacheService.get(
      CacheKeys.calendar,
      10,
      async () =>
        await apiService.get("/home/calendar/lessons").then((r) => {
          r.timetables.forEach((element) => {
            element.me = element.id === authContext.user.claims.synergeticId;
          });

          return r;
        })
    );

    setStructure(data.structure);
    setTimetables(data.timetables);
    setSelectedPerson(data.timetables[0].id);
  };

  useEffect(() => {
    getData();
  }, []);

  const onModalDismiss = () => {
    setModalOpen(false);
    setSelectedLesson(null);
  };

  const changeDay = (i) => {
    setCurrentDay((prevState) => {
      let newState = prevState + i;
      if (newState > 5) newState = 1;
      if (newState < 1) newState = 5;

      return newState;
    });
  };

  const goToToday = () => {
    setCurrentDay(new Date().getDay());
  };

  const onLessonClick = (lesson) => {
    setSelectedLesson(lesson);
  };

  const addToMyCalendarButton = (
    <CustomButton
      variant={"light"}
      size={"sm"}
      onClick={() => setModalOpen(true)}
    >
      Add to my calendar
    </CustomButton>
  );

  var days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const title = () => {
    if (view === 0) {
      return days[currentDay];
    }

    return `${days[1]} - ${days[5]}`;
  };

  return (
    <SidebarLayout title={"My Timetable"}>
      <Section bg="white">
        {timetables && timetables.length > 0 && structure && (
          <>
            <Row>
              <Col className="">
                {timetables && structure && (
                  <TabBar
                    selectedIndex={selectedIndex}
                    onTabIndexChanged={(i) => {
                      setSelectedIndex(i);
                      setSelectedPerson(timetables[i].id);
                    }}
                    tabs={timetables.map((calendar) => (
                      <>
                        <CustomAvatar
                          key={calendar.id}
                          id={calendar.id}
                          size={32}
                          name={calendar.name}
                        />
                        <span className="ms-2">{calendar.name}</span>
                      </>
                    ))}
                  />
                )}
              </Col>
            </Row>

            <Row className="mt-3">
              <Col className="d-flex justify-content-start">
                <ButtonGroup>
                  <CustomButton size={"sm"} onClick={() => changeDay(-1)}>
                    <FontAwesomeIcon icon={faChevronLeft} />
                  </CustomButton>
                  <CustomButton size={"sm"} onClick={() => changeDay(+1)}>
                    <FontAwesomeIcon icon={faChevronRight} />
                  </CustomButton>
                </ButtonGroup>
              </Col>
              <Col className="d-flex justify-content-center">
                {addToMyCalendarButton}
              </Col>

              <Col className="d-md-flex justify-content-end d-none">
                <ButtonGroup>
                  <CustomButton
                    size={"sm"}
                    variant={view === 0 ? "primary" : "light"}
                    onClick={() => setView(0)}
                    text={`Day`}
                  />
                  <CustomButton
                    size={"sm"}
                    variant={view === 1 ? "primary" : "light"}
                    onClick={() => setView(1)}
                    text={`Week`}
                  />
                </ButtonGroup>
              </Col>
            </Row>

            <Row>
              <Col className="mt-3">
                {timetables && structure && selectedPerson && (
                  <>
                    {view === 0 && (
                      <TimetableDayView
                        structure={structure}
                        day={currentDay}
                        timetable={timetables.find(
                          (x) => x.id === selectedPerson
                        )}
                        onLessonClick={onLessonClick}
                      />
                    )}
                    {view === 1 && (
                      <Row>
                        {[1, 2, 3, 4, 5].map((day) => (
                          <TimetableDayView
                            structure={structure}
                            day={day}
                            timetable={timetables.find(
                              (x) => x.id === selectedPerson
                            )}
                            onLessonClick={onLessonClick}
                          />
                        ))}
                      </Row>
                    )}
                  </>
                )}
              </Col>
            </Row>
          </>
        )}
        <div>
          {timetables && timetables.length === 0 && (
            <div className="d-flex justify-content-center align-items-center mt-5 flex-column text-muted">
              <FontAwesomeIcon icon={faCalendarXmark} size="4x" className="mb-4" />
              <h4>You do not have any timetables</h4>
            </div>
          )}
        </div>
        {!timetables && (
          <div className="d-flex justify-content-center align-items-center mt-5">
            <Loading />
          </div>
        )}
      </Section>
      <CustomModal
        handleClose={onModalDismiss}
        open={modalOpen}
        size={"lg"}
        title={"Add your timetable to your calendar"}
      >
        <AddTimetablePage id={selectedPerson} />
      </CustomModal>
      <CustomModal
        handleClose={onModalDismiss}
        open={selectedLesson !== null}
        size={"lg"}
        title={
          selectedLesson?.classDescription != null ? (
            <>{selectedLesson.classDescription}</>
          ) : (
            <></>
          )
        }
      >
        <Lesson
          lesson={selectedLesson}
          timetable={
            timetables &&
            selectedPerson &&
            timetables.find((x) => x.id === selectedPerson)
          }
        />
      </CustomModal>
    </SidebarLayout>
  );
};
