import React, { useEffect, useState } from "react";
import Loading from "../../Components/Loading";
import apiService from "../../Infrastructure/Services/ApiService";
import { loginRequest } from "../../AuthConfig";
import { Col, ListGroup, ListGroupItem, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import parsePhoneNumber from "libphonenumber-js";
import CacheService, {
  CacheKeys,
} from "../../Infrastructure/Services/CacheService";
import ContactCard from "../../Components/ContactCard";
import CustomHeader from "../../Components/CustomHeader";

export default () => {
  const [contacts, setContacts] = useState(null);

  const getContacts = async () => {
    const data = await CacheService.get(
      CacheKeys.contacts,
      60,
      async () =>
        await apiService
          .get("home/contacts")
          .then((r) => {
            return r.sort((c) => c.Order);
          })

    );
    setContacts(data);
  };

  useEffect(() => {
    getContacts();
  }, []);

  const renderTelephone = (telephoneNumber) => {
    var p = parsePhoneNumber(telephoneNumber, "AU");
    const formattedNumber = Intl.DateTimeFormat()
      .resolvedOptions()
      .timeZone.startsWith("Australia/")
      ? p.formatNational()
      : p.formatInternational();

    return (
      <div
        className="d-flex align-items-center justify-content-between w-100 mb-2"
      >
        <a href={p.getURI()}> {formattedNumber}</a>
      </div>
    );
  };

  const renderEmailAddress = (emailAddress) => {
    return (
      <div
        className="d-flex align-items-center justify-content-between w-100 text-decoration-none mb-2"
       
      >
        <a href={`mailto:${emailAddress.toLowerCase()}`}>{emailAddress.toLowerCase()}</a>
       
      </div>
    );
  };

  const renderContact = (contact) => {
    return (
      <Row key={contact}>
        <Col className="mb-3">
          <div className="mb-2">
            <b>{contact.name}</b>
          </div>
          {contact.telephone && (
            <>{renderTelephone(contact.telephone)}</>
          )}
          {contact.emailAddress && (
            <>{renderEmailAddress(contact.emailAddress)}</>
          )}
        </Col>
      </Row>
    );
  };

  const generalContacts = () => {
    {
      return contacts.filter(x => !x.contactType || x.contactType === 0).map((contact) => {
        return <>{renderContact(contact)}</>;
      });
    }
  };


  const staffContacts = () => {
    {
      return contacts.filter(x => x.contactType && x.contactType === 1).map((contact) => {
        return <> <Col xs={12} md={6} className="p-3 mb-3"><ContactCard {...contact} jobTitle={contact.title} /></Col></>;
      });
    }
  };

  const address = () => {
    return (
      <>
        <Row>
          <Col className="mb-3">
            <div className="mb-2">
              <h6>
                <b>Address</b>
              </h6>
            </div>
            <div className="mb-3">
              Kaurna Country, 1 - 23 Alison Avenue
              <br />
              Marion SA 5043 Australia
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="ratio ratio-16x9" style={{ overflow: "hidden" }}>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3268.183192237339!2d138.55051021583475!3d-35.002119086194824!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zMzXCsDAwJzA3LjYiUyAxMzjCsDMzJzA5LjciRQ!5e0!3m2!1sen!2sau!4v1540438629054"
                allowFullScreen=""
              ></iframe>
            </div>
          </Col>
        </Row>
      </>
    );
  };

  return (
    <>
      {contacts ? (
        <>
          <Row>
            <Col className="mb-3">
              <CustomHeader text="General Contacts" type="h5" />
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              {generalContacts()}
            </Col>
            {/* <Col xs={12} md={6} className="ps-md-4">
              {address()}
            </Col> */}
          </Row>

          <Row>
            <Col className="mt-3 mb-3">
              <CustomHeader text="Staff Contacts" type="h5" />
            </Col>
          </Row>
          <Row className="mt-3">

            {staffContacts()}
          </Row>
        </>
      ) : (
        <div className="w-100 text-center d-flex justify-content-center">
          <Loading />
        </div>
      )}
    </>
  );
};
