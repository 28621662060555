import { faArrowRight, faCalendar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Row } from "react-bootstrap";
import { Col, Container } from "react-bootstrap/esm";
import { Link, useHistory } from "react-router-dom";
import Carousel from "../Carousel/Carousel";
import CustomCard from "../CustomCard";
import CustomTitle from "../CustomTitle";
import { Haptics, ImpactStyle } from "@capacitor/haptics";
import "./TimetableWidget.scss";

export default ({ timetable }) => {
  const now = new Date();
  const history = useHistory();
  const formatDate = (date) => {
    const d = new Date(date);
    const isToday =
      d.getFullYear() === now.getFullYear() &&
      d.getMonth() === now.getMonth() &&
      d.getDate() === now.getDate();

    const dateParts = [];

    let minutes = d.getMinutes().toString();
    if (minutes.length == 1) {
      minutes = minutes + "0";
    }
    const hours = d.getHours();
    const ampm = hours >= 12 ? "pm" : "am";
    dateParts.push(`${hours}:${minutes}${ampm}`);

    return dateParts.join(" ");
  };

  const goToTimetable = async () => {
    await Haptics.impact({ style: ImpactStyle.Light });
    history.push("/my-timetable");
  };
  const renderLesson = (lesson, index, isLast) => {
    return (
      <CustomCard
        key={`lesson-${index}`}
        colour={"primary"}
        text={"white"}
        className={`custom-card-timetable p-2 w-100 ${
          isLast ? " me-0" : ""
        } shadow`}
        style={{ height: 150, border: 0 }}
        onClick={goToTimetable}
      >
        <span className="float-start">
          <b>{lesson.class}</b>
        </span>
        <span className="float-end">
          <b>
            {formatDate(lesson.from)} - {formatDate(lesson.until)}
          </b>
        </span>
        <br />
        {lesson.classDescription}
        <br />
        <br />
        {lesson.staff}
        <br />
        Room {lesson.room}
      </CustomCard>
    );
  };

  const items = timetable.lessons
    .sort((a, b) => new Date(a.from) - new Date(b.from))
    .map((lesson, index) => {
      return renderLesson(lesson, index, index == timetable.lessons.length - 1);
    });

  const getDayOfWeek = () => {
    const d = new Date(timetable.lessons[0].from);
    const dayOfWeek = new Intl.DateTimeFormat("en-US", {
      weekday: "long",
    }).format(d);
    return dayOfWeek;
  };

  const title = (
    <CustomTitle
      title={`My ${getDayOfWeek()}`}
      rightComponent={
        <h6 className="mb-0">
          <Link
            to="/calendar"
            className={
              "text-decoration-none font-weight-bold text-size-lg text-dark"
            }
          >
            <FontAwesomeIcon
              className="ms-1 me-0 me-sm-3"
              icon={faArrowRight}
            />
          </Link>
        </h6>
      }
    />
  );

  return (
    <>
      <div className="my-4">
        <Container>
          <Row>
            <Col
              className="ms-sm-4"
              onClick={() => history.push("/my-timetable")}
            >
              {title}
            </Col>
          </Row>
        </Container>
        <Container className="d-md-block d-none">
          <Row>
            <Col className="ps-0 ms-0">
              <Carousel items={items} />
            </Col>
          </Row>
        </Container>
        <Container fluid className="d-md-none">
          <Row>
            <Carousel items={items} />
          </Row>
        </Container>
      </div>
    </>
  );
};
