import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { loginRequest } from "../../AuthConfig";
import Loading from "../../Components/Loading";
import apiService from "../../Infrastructure/Services/ApiService";
import CacheService, {
  CacheKeys,
} from "../../Infrastructure/Services/CacheService";
import Category from "./Category";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import './AskQuestions.scss';
export default () => {
  const history = useHistory();

  const [categories, setCategories] = useState();
  

  const getQuestions = async () => {
    const data = await CacheService.get(
      CacheKeys.askWestminster,
      10,
      async () =>
        await apiService
          .get("home/ask-westminster")
          .then((r) => r)        
    );

    setCategories(data);
  };
  useEffect(() => {
    getQuestions();
  }, []);

  return (
    <>
      <Row className="mb-3">
        <Col>
          <p>
            Over the years, many questions have been asked by parents,
            caregivers, students and community members. For the quickest
            answers, please browse the categories below. If you can’t find what
            you’re looking for, you can submit an enquiry via the 'Ask a
            question' button above.
          </p>
        </Col>
      </Row>

      {categories ? (
        <>
          {categories.map((category) => (
            <Category key={category.id} category={category} />
          ))}
        </>
      ) : (
        <div className="d-flex justify-content-center align-items-center mt-5">
          <Loading />
        </div>
      )}
    </>
  );
};

const styles = {
  list: {
    listStyleType: "none",
    paddingLeft: 0,
  },
};
