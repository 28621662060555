import React, { useContext, useEffect, useState } from "react";
import { Col, Container, Modal, Row } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loading from "../../Components/Loading";
import RightBar from "../../Components/RightBar";
import Section from "../../Components/Section";
import TimetableWidget from "../../Components/Timetable/TimetableWidget";
import apiService from "../../Infrastructure/Services/ApiService";
import CacheService, {
  CacheKeys,
} from "../../Infrastructure/Services/CacheService";
import MyApps from "./MyApps";
import Birthday from "../../Components/Homepage/Birthday";
import RoleWrapper from "../../Components/RoleWrapper";
import { PageContext } from "../../Infrastructure/PageContext";
import { AuthContext } from "../../Infrastructure/AuthContext";

export default () => {
  const [data, setData] = useState(null);
  const pageContext = useContext(PageContext);
  const authContext = useContext(AuthContext);

  const getData = async () => {
    const homepageData = await CacheService.get(
      CacheKeys.homepage,
      60,
      async () => await apiService.get("/home/homepage")
    );

    if (homepageData) {
      homepageData.isNew = true;
      setData(homepageData);
    }
  };

  const onFavouriteToggle = async (id) => {
    var preCount = data.myHomepage?.favourites?.length || 0;
    const newData = await apiService.post(
      `/home/my-homepage/favourite/${id}`,
      null
    );

    const newHomepageData = { ...data };
    newHomepageData.myHomepage = newData;
    setData(newHomepageData);
    var postCount = newHomepageData.myHomepage?.favourites?.length || 0;
    CacheService.set(CacheKeys.homepage, newHomepageData, 60);

    const message =
      preCount > postCount
        ? "Link removed from favourites"
        : "Link added to favourites";
    toast.success(message);
  };

  useEffect(() => {
    if (authContext.user)
      pageContext.setPageContext({
        title: "My Westminster",
        pretitle: `Welcome back, ${authContext.user.claims.given_name}`,
      });
    getData();
    return () => {
      document.body.classList.remove("homepage");
    };
  }, []);

  useEffect(() => {
    if (
      data &&
      data.timetable &&
      data.timetable.lessons &&
      data.timetable.lessons.length > 0
    ) {
      document.body.classList.add("homepage");
    } else {
      document.body.classList.remove("homepage");
    }
  }, [data]);
  return (
    <>
      {data && (
        <>
          <ToastContainer />
          <RoleWrapper roles={["Student"]}>
            <>
              {data.isBirthday && (
                <Birthday name={authContext.user.claims.given_name} />
              )}
            </>
          </RoleWrapper>
          {data.timetable && data.timetable.lessons && (
            <TimetableWidget timetable={data.timetable} />
          )}

          <Container>
            <Row>
              <Col>
                <Section bg="white" nospacing>
                  <MyApps
                    links={data.links}
                    favourites={data.myHomepage?.favourites}
                    onFavouriteToggle={onFavouriteToggle}
                  />
                </Section>
              </Col>
              <RightBar />
            </Row>
          </Container>
          <Modal></Modal>
        </>
      )}
      {!data && (
        <div
          style={{ marginTop: '10vh' }}
          className="d-flex justify-content-center align-items-center"
        >
          <Loading />
        </div>
      )}
    </>
  );
};
