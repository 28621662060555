export default [
  { title: "Home", icon: "faHome", link: "/", activeTest: "^/$" },
  {
    title: "My Timetable",
    icon: "faCalendarAlt",
    link: "/my-timetable",
    activeTest: "^/my-timetable",
    roles: ["Staff", "Student"],
  },
  {
    title: "My Contacts",
    icon: "faContactBook",
    link: "/contacts",
    activeTest: "^/contacts$",
  },
  {
    title: "My Answers",
    icon: "faQuestionCircle",
    link: "/ask-westminster",
    activeTest: "^/ask-westminster",
  },
];
