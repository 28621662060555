import React from "react";
import Section from "../../Components/Section";
import SidebarLayout from "../../Layouts/SidebarLayout";
import { Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import IndexPage from "./IndexPage";
import CategoryPage from "./CategoryPage";
import QuestionPage from "./QuestionPage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestion } from "@fortawesome/free-solid-svg-icons";
import { isMobile } from "react-device-detect";
import "react-spring-bottom-sheet/dist/style.css";
import CustomButton from "../../Components/CustomButton";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import './AskQuestions.scss';
export default () => {
  const { categorySlug, categoryId, questionId, questionSlug } = useParams();
  const history = useHistory();

  const askAQuestionButton = (
    <CustomButton
      variant="outline-light"
      size={isMobile ? "sm" : ""}
      onClick={() => history.push("/ask-westminster/ask-a-question")}
    >
      <FontAwesomeIcon icon={faQuestion} className="me-2"></FontAwesomeIcon>
      Ask a Question
    </CustomButton>
  );

  return (
    <SidebarLayout title={"My Answers"} rightComponent={askAQuestionButton}>
      <Section bg="white">
        <>
          <Row>
            {!categorySlug && !questionSlug && <IndexPage />}
            {categorySlug && !questionSlug && (
              <CategoryPage
                categoryId={categoryId}
                categorySlug={categorySlug}
              />
            )}
            {categorySlug && questionSlug && (
              <QuestionPage
                categoryId={categoryId}
                categorySlug={categorySlug}
                questionId={questionId}
                questionSlug={questionSlug}
              />
            )}
          </Row>
        </>
      </Section>
    </SidebarLayout>
  );
};
