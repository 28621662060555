import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import {
  Col,
  Row,
  Form,
  InputGroup,
} from "react-bootstrap";
import { loginRequest } from "../../AuthConfig";
import Loading from "../../Components/Loading";
import apiService from "../../Infrastructure/Services/ApiService";

export default ({ id }) => {
  const [calendarData, setCalendarData] = useState(null);
  const [calendarUrlText, setCalendarUrlText] = useState(null);
  const [calendarUrlTextColour, setCalendarUrlTextColour] = useState(null);

  const platformLinks = [
    { icon: "outlook", title: "Outlook<br/>(Web)", link: "" },
    { icon: "outlook", title: "Outlook<br/>(Windows)", link: "" },
    { icon: "outlook", title: "Outlook<br/>(iOS/Android)", link: "" },
    {
      icon: "apple",
      title: "iPhone/iPad",
      link: "https://support.apple.com/en-gb/guide/iphone/iph3d1110d4/ios#iph871c78745",
    },
    { icon: "google", title: "Google<br/>Calendar", link: "" },
    { icon: "android", title: "Google<br/>Calendar", link: "" },
  ];

  const getIcon = (link) => {
    switch (link.icon) {
      case "outlook":
        return (
          <img
            src={require("../../Assets/img/outlook.png")}
            alt={link.title}
            style={styles.logo}
          />
        );
      case "apple":
        return (
          <img
            src={require("../../Assets/img/apple.png")}
            alt={link.title}
            style={styles.logo}
          />
        );
      case "google":
        return (
          <img
            src={require("../../Assets/img/google.png")}
            alt={link.title}
            style={styles.logo}
          />
        );
    }
  };

  const copyLink = async () => {
    navigator.clipboard.writeText(calendarData.url);
    setCalendarUrlText("Copied!");
    setCalendarUrlTextColour("#00502f");
    setTimeout(() => {
      setCalendarUrlText(calendarData.url);
      setCalendarUrlTextColour("#000");
    }, 3000);
  };
  const getData = async () => {
    const homepageData = await apiService
      .get(
        `/home/calendar/lessons/geticalurl/${id}?r=` + Math.random() * 100000
      );
   

    setCalendarData(homepageData);
    setCalendarUrlText(homepageData.url);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      {calendarData ? (
        <>
          <Row>
            <Col>
              <b className="d-block mb-2 w-100">Step 1:</b>
              Copy the web address below. This is your unique address to your
              timetable file.
            </Col>
          </Row>
          <Row>
            <Col>
              <InputGroup className="mb-3 mt-2">
                <Form.Control
                  readonly
                  value={calendarUrlText}
                  style={{ fontSize: "small", color: calendarUrlTextColour }}
                />
                <InputGroup.Text className="cursor-pointer" onClick={copyLink}>
                  <FontAwesomeIcon icon={faCopy} />
                </InputGroup.Text>
              </InputGroup>
            </Col>
          </Row>
          <Row>
            <Col className="mb-3">
              <b className="d-block mb-2 w-100">Step 2:</b>
              Follow the instructions depending on your calendar platform:
            </Col>
          </Row>
          <Row>
            <Col>
              <b>Outlook (Windows)</b>
              <ol>
                <li style={styles.listItem}>Open your calendars in Outlook.</li>
                <li style={styles.listItem}>
                  Right click 'My Calendars' and go to Add Calendar &gt; From
                  Internet.
                </li>
                <li style={styles.listItem}>Paste the URL copied from above</li>
                <li style={styles.listItem}>Press OK</li>
              </ol>
            </Col>
          </Row>
          <Row>
            <Col>
              <b>Outlook (Web)</b>
              <ol>
                <li style={styles.listItem}>Sign in to Outlook on the web.</li>
                <li style={styles.listItem}>
                  At the bottom of the page, select{" "}
                  <img
                    src={require("../../Assets/img/outlook-web-step-2.png")}
                  />{" "}
                  to go to Calendar.
                </li>
                <li style={styles.listItem}>
                  In the navigation pane, select Import calendar.
                  <br />
                  <img
                    src={require("../../Assets/img/outlook-web-step-3.png")}
                  />
                </li>
                <li style={styles.listItem}>
                  Under Import calendar, select From web.
                </li>
                <li style={styles.listItem}>
                  Under Link to the calendar, type the URL for the linked
                  calendar.
                </li>
                <li style={styles.listItem}>
                  Under Calendar name, type the name you want to use for the
                  calendar.
                </li>
                <li style={styles.listItem}>Select Import.</li>
              </ol>
            </Col>
          </Row>
          <Row>
            <Col>
              <b>iPhone/iPad</b>
              <ol>
                <li style={styles.listItem}>Open your Calendar App.</li>
                <li style={styles.listItem}>
                  Tap Calendars at the bottom of the screen, then tap Add
                  Calendar.
                </li>
                <li style={styles.listItem}>
                  Tap Add Subscription Calendar, enter the URL you copied above,
                  then click Subscribe.
                </li>
              </ol>
            </Col>
          </Row>
        </>
      ) : (
        <Row>
          <Col className="d-flex justify-content-center align-items-center">
            <Loading />
          </Col>
        </Row>
      )}
    </>
  );
};

const styles = {
  logo: { maxWidth: 60, maxHeight: 60 },
  listItem: { marginBottom: 6 },
};
