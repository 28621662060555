import {
  InteractionRequiredAuthError,
  PublicClientApplication,
} from "@azure/msal-browser";
import { config, scopes } from "../../AuthConfig";
import { Capacitor } from "@capacitor/core";
import { MsAuthPlugin } from "@recognizebv/capacitor-plugin-msauth";
import PubSub from "pubsub-js";

const application = new PublicClientApplication(config);

export default class AuthService {
  static login = async () => {
    if (!Capacitor.isNativePlatform()) {
      const redirectResponse = await application.handleRedirectPromise();
      if (redirectResponse !== null) {
        // Acquire token silent success
        return redirectResponse;
      } else {
        // MSAL.js v2 exposes several account APIs, logic to determine which account to use is the responsibility of the developer
        const accounts = application.getAllAccounts();
        const account = accounts[0];
        const accessTokenRequest = {
          scopes: scopes.web,
          account: account,
        };
        if (accounts.length === 0) {
          application.acquireTokenRedirect(accessTokenRequest);
        }

        try {
          const accessTokenResponse = await application.acquireTokenSilent(
            accessTokenRequest
          );

          return accessTokenResponse;
        } catch (error) {
          //Acquire token silent failure, and send an interactive request
          if (error instanceof InteractionRequiredAuthError) {
            application.acquireTokenRedirect(accessTokenRequest);
          }
        }
      }
    } else {
      const loginOptions = {
        knownAuthorities: [config.auth.authorityDomain],
        clientId: config.auth.clientId,
        authority: config.auth.authority,
        redirectUri: config.auth.redirectUri,
        tenant: config.auth.tenant,
        scopes: scopes.mobile,
        authorityType: "B2C",
        authorityUrl: config.auth.authorityUrl
      };
      const response = await MsAuthPlugin.login(loginOptions);
   
      return response;
    }
  };
  static logout = async () => {
    if (Capacitor.isNativePlatform()) await MsAuthPlugin.logout();
    else {
      application.logoutRedirect(config);
    }
  };

  static isUserSignedInWeb = () => {
    const activeAccount = application.getActiveAccount();
    return activeAccount;
  };

}
