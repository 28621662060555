export const config = {
  auth: {
    tenant: "westminsterb2c.onmicrosoft.com",
    authorityDomain: "westminsterb2c.b2clogin.com",
    clientId: "c00b4e22-4aab-4e9f-9e14-636539a52fc4",
    redirectUri: "/signin-oidc", //defaults to application start page
    postLogoutRedirectUri: "/login",
    authorityUrl:
      "https://westminsterb2c.b2clogin.com/westminsterb2c.onmicrosoft.com/B2C_1A_SIGNIN",
    authority:
      "https://westminsterb2c.b2clogin.com/westminsterb2c.onmicrosoft.com/B2C_1A_SIGNIN", // Choose sign-up/sign-in user-flow as your default.
    knownAuthorities: ["westminsterb2c.b2clogin.com"],
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false,
  },
};

const baseScopes = ["https://westminsterb2c.onmicrosoft.com/api/access"];
export const scopes = {
  web: ["openid", "profile", "offline_access", ...baseScopes],
  mobile: baseScopes,
};
