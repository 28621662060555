import { faCircleQuestion } from "@fortawesome/free-regular-svg-icons";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom";
export default ({ item }) => {
  let history = useHistory();

  const goBack = (e) => {
    e.preventDefault();
    history.goBack();
  };
  return (
    <div className="text-center text-muted p-5">
      <FontAwesomeIcon icon={faCircleQuestion} size="5x" className="mb-3" />
      <h4>{item} not found</h4>
      <p>
        Please{" "}
        <a href="#" onClick={goBack}>
          go back
        </a>{" "}
        and try again.
      </p>
    </div>
  );
};
