import {
  faLink,
  faPlus,
  faWindowRestore,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import CustomTitle from "../../Components/CustomTitle";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { faStar as emptyStar } from "@fortawesome/free-regular-svg-icons";
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip } from "react-tooltip";
import CustomButton from "../../Components/CustomButton";
import { useLongPress } from "use-long-press";
import { isMobile } from "react-device-detect";

export default ({ links, favourites, onFavouriteToggle }) => {
  const [hoverLinkId, setHoverLinkId] = useState(null);
  let timer;
  const onHover = (id) => {
    if (id == null) {
      timer = setTimeout(() => {
        if (id !== hoverLinkId) setHoverLinkId(null);
      }, 2000);
      return;
    }
    clearTimeout(timer);
    setHoverLinkId(id);
  };
  return (
    <>
      <CustomTitle title={`My Favourites`} variant={"h2"} />
      <Row>
        {links.map((category) =>
          category.links
            .filter((a) => favourites && favourites.indexOf(a.id) > -1)
            .sort((a, b) => a.order - b.order)
            .map((link) => (
              <AppLink
                link={link}
                key={link.id}
                favourite
                onFavouriteToggle={onFavouriteToggle}
                onMouseEnter={() => onHover(link.id)}
                onMouseLeave={() => onHover(null)}
              />
            ))
        )}
        {(!favourites || favourites.length === 0) && (
          <Col className="text-center p-3 text-muted">
            <FontAwesomeIcon icon={faLink} size="4x" className="mb-4" />
            <p>
              <b>You do not have any favourites saved</b>
              <br />
              To add a link to your favourites, hover over one of the links
              below and click 'Add to My Favourites'
            </p>
          </Col>
        )}
      </Row>
      {links.map((category) => (
        <>
          <CustomTitle title={`${category.name}`} variant={"h2"} />
          <Row className="mb-5">
            {category.links
              .sort((a, b) => a.order - b.order)
              .map((link) => (
                <AppLink
                  onMouseEnter={() => onHover(link.id)}
                  onMouseLeave={() => onHover(null)}
                  link={link}
                  key={link.id}
                  favourite={favourites && favourites.indexOf(link.id) > -1}
                  onFavouriteToggle={onFavouriteToggle}
                />
              ))}
          </Row>
        </>
      ))}
      <Tooltip id="link-tooltip" clickable isOpen={hoverLinkId !== null}>
        <a
          href="#"
          style={styles.addToFavouritesLink}
          onClick={(e) => {
            e.preventDefault();
            onFavouriteToggle(hoverLinkId);
          }}
        >
          {favourites && favourites.indexOf(hoverLinkId) > -1
            ? "Remove from My Favourites"
            : "Add to My Favourites"}
        </a>
      </Tooltip>
    </>
  );
};

const AppLink = ({ link, onMouseEnter, onMouseLeave }) => {
  return (
    <Col
      xs={6}
      sm={6}
      md={3}
      lg={3}
      xl={2}
      className="text-center p-3 mb-5 noselect"
      data-tooltip-id="link-tooltip"
      data-tooltip-place="top"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <a href={link.webUrl} target="_blank">
        <span style={styles.appLinkContainer}>
          <img
            src={`https://mywestminsterpublic.blob.core.windows.net/homepage/${link.id}.webp`}
            alt={link.title}
            style={styles.appLink}
          />
        </span>
        <span className="d-inline-block mt-3">{link.name}</span>
      </a>
    </Col>
  );
};

const styles = {
  appLink: { maxHeight: 60, maxWidth: 60 },
  appLinkContainer: {
    height: 60,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  favouriteButton: {
    float: "right",
    zIndex: 10,
    position: "absolute",
    marginTop: -10,
    marginLeft: 26,
    cursor: "pointer",
  },
  favouritedLink: { color: "#ffc107" },
  unfavouritedLink: { color: "#adb5bd" },
  myLinksEmptyState: {
    border: 3,
    borderStyle: "dashed",
    borderColor: "#efefef",
  },
  addToFavouritesLink: { color: "#fff", textDecoration: "none" },
};
