import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { loginRequest } from "../../AuthConfig";
import Loading from "../../Components/Loading";
import apiService from "../../Infrastructure/Services/ApiService";
import CacheService, {
  CacheKeys,
} from "../../Infrastructure/Services/CacheService";
import Category from "./Category";
import NotFound from "../../Components/NotFound";
import './AskQuestions.scss';
export default ({ categorySlug, questionSlug, categoryId, questionId }) => {
  const [question, setQuestion] = useState();
  const [loaded, setLoaded] = useState(false);

  const getQuestions = async () => {
    const data = await apiService
      .get(`home/ask-westminster/${categoryId}/${questionId}`)
      .then((r) => {
        return r;
      });

    setLoaded(true);
    setQuestion(data);
  };
  useEffect(() => {
    getQuestions();
  }, []);

  const formatText = (text) => {
   
    if (!text) return text;
    if (text.startsWith("?")) return text.substr(1);
    if (text.startsWith('&nbsp;'))
      text = text.replace('&nbsp;', '');
      
    return text.trim();
  };
  const render = (blocks) => {
    var convertedHtml = "";
    blocks.map((block) => {
      switch (block.type) {
        case "header":
          convertedHtml += `<h${block.data.level}>${formatText(
            block.data.text
          )}</h${block.data.level}>`;
          break;
        case "embded":
          convertedHtml += `<div><iframe width="560" height="315" src="${block.data.embed}" frameborder="0" allow="autoplay; encrypted-media" allowFullScreen></iframe></div>`;
          break;
        case "paragraph":
          convertedHtml += `<p>${formatText(block.data.text)}</p>`;
          break;
        case "delimiter":
          convertedHtml += "<hr />";
          break;
        case "image":
          convertedHtml += `<img className="img-fluid" src="${block.data.file.url}" title="${block.data.caption}" /><br /><em>${block.data.caption}</em>`;
          break;
        case "list":
          convertedHtml += "<ul>";
          block.data.items.forEach(function (li) {
            console.log(li);
            convertedHtml += `<li>${li.content}</li>`;
          });
          convertedHtml += "</ul>";
          break;
        default:
          console.log("Unknown block type", block.type);
          break;
      }
    });

    convertedHtml = convertedHtml.replaceAll("<a ", "<a target=\"_blank\" ");
    return convertedHtml;
  };

  const renderBreadcrumb = () => {
    const selectedCategory = question.categories.find(
      (x) => x.id == categoryId
    );

    return (
      <p>
        <Link to="/ask-westminster">My Answers</Link>
        {selectedCategory && (
          <>
            {" "}
            /{" "}
            <Link
              to={`/ask-westminster/${selectedCategory.id}/${selectedCategory.slug}`}
            >
              {selectedCategory.name}
            </Link>
          </>
        )}
      </p>
    );
  };

  return (
    <>
      {loaded ? (
        <>
          <Col xs={12} className="mb-3">
            {question ? (
              <>
                <div className="mb-4">{renderBreadcrumb()}</div>
                <h4 className="mb-3">{question.question}</h4>
                <div
                className=" ask-container"
                style={{fontSize: '1rem'}}
                  dangerouslySetInnerHTML={{
                    __html: render(JSON.parse(question.answer).blocks),
                  }}
                ></div>
              </>
            ) : (
              <NotFound item={"Question"} />
            )}
          </Col>
        </>
      ) : (
        <div className="d-flex justify-content-center align-items-center mt-5">
          <Loading />
        </div>
      )}
    </>
  );
};

const styles = {
  list: {
    listStyleType: "none",
    paddingLeft: 0,
  },
};
