export default {
  IsInRole: (user, role) => {
    if (!user || !user.claims || !user.claims.roles) return false;

    return user.claims.roles.indexOf(role) > -1;
  },
  Id: (user) => {
    return user.claims.synergeticId;
  },
};
