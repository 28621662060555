import React, { useEffect, useRef, useState } from "react";
import Carousel from "../../Components/Carousel/Carousel";
import "./Splash.scss";
import CustomButton from "../../Components/CustomButton";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Capacitor } from "@capacitor/core";

export default () => {
  const steps = 3;
  const history = useHistory();
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (!Capacitor.isNativePlatform()) {
      history.push("/login");
      return;
    }

    setLoaded(true);
  }, []);

  const items = () => {
    return [1, 2, 3].map((i) => (
      <div
        style={styles.carouselItem}
        className="d-flex align-content-center align-items-center flex-column"
      >
        <div style={{ height: "80%" }}>{i}</div>
        <div style={{ width: "80vw" }}>
          <CustomButton
            outline
            variant="outline-light"
            className="w-100"
            onClick={next}
          >
            Next
          </CustomButton>
        </div>
      </div>
    ));
  };

  const next = () => {
    const screenWidth = window.innerWidth;
    const left = document.getElementById("splash-carousel").scrollLeft;
    if (left < (steps - 1) * screenWidth) {
      document
        .getElementById("splash-carousel")
        .scrollTo(left + screenWidth, 0);
    } else {
      history.push("/login");
    }
  };
  return (
    <>
      {loaded ? (
        <div className="onboarding">
          <Carousel step={1} items={items()} id="splash-carousel" />
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

const styles = {
  carouselItem: { width: "100vw", height: "100vh", background: "#00502f" },
};
