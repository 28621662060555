import React, { useState, useEffect, useMemo, useRef } from "react";
import { Col, Form, InputGroup, Row } from "react-bootstrap";
import IdentityUtils from "../../Infrastructure/IdentityUtils";
import { DebounceInput } from "react-debounce-input";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAddressBook,
  faPhone,
  faEnvelope,
  faSpinner,
  faCircleNotch,
} from "@fortawesome/free-solid-svg-icons";
import apiService from "../../Infrastructure/Services/ApiService";
import Avatar from "react-avatar";
import Loading from "../../Components/Loading";
import ContactCard from "../../Components/ContactCard";
import CustomHeader from "../../Components/CustomHeader";

export default () => {
  const regex = /^[\\w\\-\\s]+$/;
  const [searchValue, setSearchValue] = useState("");
  const [searchResults, setSearchResults] = useState(null);
  const [myContacts, setMyContacts] = useState(null);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (searchValue.length === 0 && searchResults && searchResults.length > 0)
      setSearchResults([]);
    if (searchValue.length < 4) return;
    setLoading(true);
    doSearch(searchValue);
  }, [searchValue]);

  useEffect(() => {
    getPeople();
  }, []);

  const getPeople = async () => {
    const recentPeople = await apiService
      .get(`home/contacts/people`);    

    const people = recentPeople.map((p) => ({
      ...p,
      id: p.employeeId,
      jobTitle: p.position,
    }));
    setMyContacts(people);
  };

  const doSearch = async (input) => {
    const sanitisedInput = input.replace(regex, "");
    const apiSearchResults = await apiService
      .get(`home/contacts/search?q=${sanitisedInput}`);
      
    var results = apiSearchResults.map((p) => ({ ...p, jobTitle: p.position }));
    setSearchResults(results);
    setLoading(false);
  };
  return (
    <>
      <Row>
        <Col>
          <InputGroup>
            <DebounceInput
              className="form-control border-end-0 form-control-lg"
              placeholder="Search for a contact..."
              minLength={1}
              debounceTimeout={300}
              value={searchValue}
              onChange={(event) => setSearchValue(event.target.value)}
            />
            <div className="input-group-text bg-white border-start-0">
              {loading && <FontAwesomeIcon icon={faCircleNotch} spin />}
            </div>
          </InputGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          {!searchResults || (searchResults && searchResults.length) == 0 ? (
            <>
              <Row>
                <Col className="mt-5 mb-3">
                  <CustomHeader text="My Contacts" type="h5" />
                </Col>
              </Row>
              <Row>
                {!myContacts &&
                  [1, 2, 3, 4, 5].map((i) => (
                    <Col xs={12} md={4} className="p-3">
                      <ContactCard />
                    </Col>
                  ))}
                {myContacts &&
                  myContacts.map((contact) => (
                    <Col xs={12} md={4} className="p-3">
                      <ContactCard {...contact} />
                    </Col>
                  ))}
              </Row>
            </>
          ) : (
            <>
              <Row>
                <Col className="mt-5 mb-3">
                  <h5>Search Results</h5>
                </Col>
              </Row>
              <Row>
                {searchResults.map((contact) => (
                  <Col key={contact.id} xs={12} md={4} className="p-3">
                    <ContactCard {...contact} />
                  </Col>
                ))}
              </Row>
            </>
          )}
        </Col>
      </Row>
    </>
  );
};
