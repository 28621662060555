import React, { useContext, useEffect, useState } from "react";
import SidebarLayout from "../../Layouts/SidebarLayout";
import Section from "../../Components/Section";
import { Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faContactBook,
  faExclamationTriangle,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import ContactsSearch from "./ContactsSearch";
import IdentityUtils from "../../Infrastructure/IdentityUtils";
import TabBar from "../../Components/Tabs/TabBar";
import GeneralContacts from "./GeneralContacts";
import IncidentContacts from "./IncidentContacts";
import { AuthContext } from "../../Infrastructure/AuthContext";



export default () => {
  
  const [selectedTab, setSelectedTab] = useState(0);
  const [tabs, setTabs] = useState();
  const authContext = useContext(AuthContext);
  const isStaffMember = IdentityUtils.IsInRole(
    authContext.user,
    "Staff"
  );

  const renderTab = (icon, text) => {
    return (
      <div>
        {icon && <FontAwesomeIcon icon={icon} />}
        <span className="ms-2">{text}</span>
      </div>
    );
  };

  useEffect(() => {
    const tabList = [];
    if (isStaffMember) {
      tabList.push(renderTab(faContactBook, "Contacts"));
    }

    tabList.push(renderTab(faPhone, "General Contacts"));
    if (isStaffMember) {
      tabList.push(renderTab(faExclamationTriangle, "Incident Contacts"));
    }

    setTabs(tabList);
  }, []);

  return (
    <SidebarLayout title="My Contacts">
      <Section bg="white">
        {tabs && (
          <>
            <TabBar
              tabs={tabs}
              selectedIndex={selectedTab}
              onTabIndexChanged={setSelectedTab}
            />

            <Row>
              <Col>
                {selectedTab === 0 && isStaffMember && <ContactsSearch />}
                {(selectedTab === 1 && isStaffMember) ||
                (selectedTab === 0 && !isStaffMember) ? (
                  <GeneralContacts />
                ) : (
                  <></>
                )}
                {selectedTab === 2 && isStaffMember && <IncidentContacts />}
              </Col>
            </Row>
          </>
        )}
      </Section>
    </SidebarLayout>
  );
};
